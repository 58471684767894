.content {
  display: flex;
  &:hover {
    cursor: pointer;
  }
}

.left_icon {
  justify-self: center;
  padding: 14px 0 10px 14px;
}

.right_status {
  position: absolute;
  right: 18px;
  top: 35%;
  justify-content: center;
  padding: 18px 10px 0 0;
  width: 30px;
}

.body {
  flex: 1;
  max-width: 520px;
  padding: 10px 14px;
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
}

.title_icon {
  margin-right: 8px;
}

.title_name {
  text-overflow: ellipsis;
  overflow-x: hidden;
  font-size: 14px;
  white-space: nowrap;
  align-self: flex-end;
}

.description {
  word-wrap: break-word;
  font-size: 12px;
}

.time {
  font-size: 12px;
  color: #bdbdbd;
}

.actions {
  display: none;
  padding-top: 14px;
}

.content:hover .actions {
  display: block;
}
