.header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
}

.actions_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.search_input {
  width: 300px;
}

.sort_icon {
  width: 20px;
  height: 20px;
}

.search_icon {
  padding-right: 5px;
  color: rgba(255, 255, 255, 0.25);
}
