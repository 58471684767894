@keyframes opacityAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.page {
  display: flex;
  height: 100vh;
  margin: 0;
}

.main_part_side_menu_opened {
  @media (min-width: 900px) {
    width: calc(100% - 258px);
    transition: 0.2s;
  }
}

.main_part_side_menu_closed {
  width: calc(100% - 50px);
  transition: 0.2s;
}

@media (max-width: 900px) {
  .main_part {
    width: 100%;
  }
}

.loading_container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader_icon {
  font-size: 20px;
  color: var(--deloitte-green);
}

/* Scrollbar style -- START */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  position: absolute;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 20px;
  right: -10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #444;
}
/* Scrollbar style -- END */
