.form {
  padding: 10px 2px;
  display: flex;
  flex-direction: column;
}

.form_item_label {
  margin-right: 15px;
}

.tooltip_hint_title {
  margin-bottom: 10px;
  font-weight: 700;
}

.language_input {
  margin-bottom: 40px;
}

.tool_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button_icon {
  width: 20px;
  height: 20px;
}

.modal_icon {
  width: 30px;
  height: 30px;
}

.bold_italic_text {
  font-style: italic;
  font-weight: 700;
}
