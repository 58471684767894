.team_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  margin: 0 10px 0 10px;
  border: 1px solid #525860;
  border-radius: 8px;
  user-select: none;
  animation: opacityAnimation 0.3s ease-in-out;
  font-weight: 700;
  box-shadow: 0 -8px 6px 10px rgb(38 39 48);
  z-index: 1;
  &:hover {
    cursor: pointer;
    background-color: #525860;
  }
}

.team_name {
  font-weight: 400;
  font-style: italic;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  padding-right: 2px;
}

.team_menu {
  max-height: 320px;
  overflow-y: auto;
  margin-bottom: 4px;
  border-radius: 8px;
  background-color: #1f1f1f;
}