@keyframes opacityAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tool {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #23232a;
  margin: 10px 0;
  animation: opacityAnimation .3s ease-in-out;
}

.tool_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tool_title {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.ribbon {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  --r: .4em;
  border-inline: .5em solid #0000;
  padding: .2em .2em calc(var(--r) + .1em);
  clip-path: polygon(0 0,100% 0,100% 100%,calc(100% - .5em) 100%,50% calc(100% - var(--r)),.5em 100%,0 100%);
  background:
   radial-gradient(50% .1em at top,#000a,#0000) border-box,
   var(--deloitte-green) padding-box;
}

.tool_title_text {
  font-size: 24px;
}

.templates_container {
  padding-right: 12px;
}

.template_option {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.delete_icon {
  width: 18px;
  height: 18px;
}

.tool_content {
  padding: 10px;
}