.carousel_with_arrows {
  margin-top: 10px;
  margin-left: 100px;
  margin-right: 100px;
}

.tool_selector {
  text-align: center;
  color: #fff;
  line-height: 40px;
  height: 40px;
  font-size: 16px;
  cursor: pointer;
}

.tool_selector:hover {
  color:var(--deloitte-green);
}

.tool_selector_selected {
  /* color: #93b744; */
  border-bottom: var(--deloitte-green) solid;
  border-bottom-width: thin;
}

.tool_selector_icon {
  width: 18px;
  height: 18px;
}
