.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}

.subTitle {
  font-size: 14px;
  font-weight: 400;
}

.close_button {
  height: 40px;
  background-color: #212529;
  border: 1px solid #9c9c9c;

  &:hover {
    background-color: #0e11174c;
  }
}

.submit_button {
  height: 40px;
  background-color: var(--deloitte-green);
  border: 1px solid #777;
}

.form {
  padding: 10px 2px;
  display: flex;
  flex-direction: column;
}
