.sort_icon {
  width: 20px;
  height: 20px;
}

.checkbox_container {
  width: 100%;
  display: flex;
  align-items: center;
}

.checkbox_content {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
}

.file_name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}

.nested_files {
  margin-left: 20px;
}

.list_item {
  padding-left: 0px !important;
  justify-content: flex-start !important;
}

.search_input {
  width: calc(100% - 20px);
  margin: 0 10px 10px;
}

.files_list_title {
  width: 100%;
  padding: 6px 6px 6px 5px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.files_sort_button {
  color: #fff;
  line-height: 1;
}

.list {
  height: calc(100vh - 60px - 40px - 62px - 42px - 44px);
  overflow-y: auto;
}

.empty_state {
  font-size: 14px;
  text-align: center;
  margin: 20px;
  border: 1px solid #525252;
  border-radius: 5px;
  padding: 20px;
}

.empty_state_title {
  margin: 10px 0 5px;
  font-size: 16px;
  font-weight: 700;
}
