.content {
  flex: 1;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
}

.add_contract_type {
  padding: 0 0 20px 40px;
}

.template_type_item_content {
  display: flex;
  flex-direction: column;
  background-color: #141414;
  margin: 0 20px 20px 40px;
}

.template_type_item_title {
  display: flex;
  align-items: center;
  padding: 12px 20px 20px 24px;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #141414;
  font-weight: bold;
  border-bottom: solid 1px #303030;
  margin-bottom: 20px;
}

.bold_italic_text {
  font-style: italic;
  font-weight: 700;
}
