.result_actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical_divider {
  width: 2px;
  height: 16px;
  background-color: #fff;
  margin: 0 8px;
}
