@keyframes opacityAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.rainbow_text {
  background-image: linear-gradient(
    to right,
    #ff0000,
    #ff8000,
    #ffff00,
    #00ff00,
    #00ffff,
    #0000ff,
    #8000ff
  ) !important;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  transition: all 0.3s ease;
}

.czech_flag {
  background: linear-gradient(to bottom, #11457e 100%, transparent 50%),
    /* Blue triangle */ linear-gradient(to bottom, white 55%, #d7141a 45%);
  /* White and red */
  background-size: 30% 100%, 70% 100%;
  background-position: left, right;
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  transition: all 0.3s ease;
}

.polish_flag {
  background-image: linear-gradient(
    to right,
    #ffffff 50%,
    #dc143c 50%
  ) !important;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  transition: all 0.3s ease;
}

.hungarian_flag {
  background: linear-gradient(
    to bottom,
    #cd2a3e 50%,
    white 50% 60%,
    #468847 10%
  ); /* Red, White, Green */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  transition: all 0.3s ease;
}

.serbian_flag {
  background: linear-gradient(
    to bottom,
    #ff0000 50%,
    #11457e 50% 60%,
    white 10%
  ); /* Adjusted proportions */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  transition: all 0.3s ease;
}

.ukrainian_flag {
  background-image: linear-gradient(
    to right,
    #005bbb 50%,
    #ffd500 50%
  ) !important;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  transition: all 0.3s ease;
}

.latvian_flag {
  background-image: linear-gradient(
    to right,
    #9e3039 40%,
    #ffffff 40%,
    #ffffff 60%,
    #9e3039 60%
  ) !important;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  transition: all 0.3s ease;
}

.lithuanian_flag {
  background-image: linear-gradient(
    to right,
    #fdb913 33%,
    #006a44 33%,
    #006a44 66%,
    #c1272d 66%
  ) !important;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  transition: all 0.3s ease;
}

.romanian_flag {
  background-image: linear-gradient(
    to right,
    #002b7f 33%,
    #fcd116 33%,
    #fcd116 66%,
    #ce1126 66%
  ) !important;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  transition: all 0.3s ease;
}

.toggle_menu_button {
  display: flex;
  padding: 4px 6px 6px 4px;
  color: #9ea6a2;
  font-size: 20px;
  transition: transform 0s;
  position: absolute;
  right: 10px;
}

.toggle_menu_button.closed {
  transform: rotate(180deg);
}

.toggle_menu_button:hover {
  background-color: #525860;
  cursor: pointer;
  border-radius: 15%;
}

.page_title_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  height: 60px;
  padding: 10px;
  animation: opacityAnimation 0.3s ease-in-out;
}

.home_link {
  text-decoration: none;
}

.page_title {
  display: flex;
  align-items: center;
  animation: opacityAnimation 0.3s ease-in-out;
  user-select: none;
  position: relative;
  cursor: pointer;
}

.page_title_text {
  font-family: "Open Sans", Helvetica, sans-serif;
  margin: 0;
  margin-left: 4px;
  font-size: 21px;
  font-weight: 200;
  color: #fff;
}

.page_title_image {
  width: 36px;
  height: 36px;
  margin-right: 5px;
}

.fade {
  transition: opacity 0.3s ease-in-out;
}

.loading_container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader_icon {
  font-size: 20px;
  color: var(--deloitte-green);
}
