.page {
  padding: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 24px;
}

.tab_label {
  padding: 0 15px;
}

.actions_bar_container {
  text-align: center;
}
