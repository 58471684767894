.form {
  padding: 10px 2px;
  display: flex;
  flex-direction: column;
}

.language_input {
  margin-bottom: 40px;
}

.button_icon {
  width: 20px;
  height: 20px;
}

.modal_icon {
  width: 30px;
  height: 30px;
}

.tool_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.description_tooltip {
  max-width: 600px;
  white-space: normal;
  word-wrap: break-word;
}

.bold_italic_text {
  font-style: italic;
  font-weight: 700;
}
