.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* overflow: hidden auto; */
  padding: 0 16px 16px 16px;
  height: calc(100% - 60px);
}

.searchbar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.tools_tab {
  overflow: hidden auto;
}

.searchresults {
  overflow-y: auto;
  padding: 0 16px 16px 16px;
  /* max-height: 75vh; */
}

.in_dev {
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
}

.result_actions {
  display: flex;
  align-items: center;
}

.column {
  align-content: start;
}

.batch_actions_menu {
  display: flex;
  justify-content: start;
  padding: 0 16px 0 22px;
  align-items: center;
}

.aicontainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.aichild {
  flex-grow: 1;
}

.highlight {
  background-color: #75a321;
  font-weight: 600;

  padding-right: 4px;
}

.searchresults em {
  font-style: normal;
  background-color: rgb(117, 163, 33, 0.5);
  font-weight: 600;
  padding-right: 4px;
}

.searchresults {
  overflow-y: auto;
}

#listboxContainer > div > div:nth-child(2) {
  z-index: 1000 !important;
}

.bottom_buttons_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
