@keyframes slideIn {
  from {
    top: 55%;
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

.page {
  display: flex;
  height: 100vh;
  margin: 0;
  flex: 1;
}

.teams_menu {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 258px;
  border-right: 1px solid #262730;
}

.user_section {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 10px 0;
}

.user_button {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 6px;
  margin: 0 6px 0 10px;
  font-size: 14px;
  border: 1px solid #525860;
  border-radius: 8px;
  user-select: none;
  animation: opacityAnimation 0.3s ease-in-out;
  font-weight: bold;
  max-width: 238px;
  background-color: #262730;
}

.user_button:hover {
  cursor: pointer;
  background-color: #525860;
}

.user_notifications {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  margin: 0 10px 0 0;
  font-size: 14px;
  border: 1px solid #525860;
  border-radius: 8px;
  user-select: none;
  animation: opacityAnimation 0.3s ease-in-out;
  font-weight: bold;
  position: relative;
  background-color: #262730;
}

.user_notifications:hover {
  cursor: pointer;
  background-color: #525860;
}

.popup_menu_title {
  display: flex;
  padding: 10px 0 10px 10px;
  overflow-x: hidden;
}

.popup_menu_item {
  padding: 6px 0 6px 10px;
  display: flex;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
}

.popup_menu_item:hover {
  background-color: #9d9d9d;
  cursor: pointer;
}

.page_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px 16px 16px;
  font-size: 24px;
  font-weight: bold;
}

.add_team_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #525860;
  padding: 10px;
}

.teams_list {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  overflow-y: auto;
  border-top: 1px solid #262730;
}

.teams_list_item {
  position: relative;
  display: flex;
  /* justify-content: space-between; */
  align-self: center;
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  padding: 8px 16px 8px 16px;
}

.teams_list_item_name {
  flex: 1;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 26px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: #b7b7b7;
  }
}

.teams_list_item_selected {
  background-color: #262730;
}

.edit_icon {
  display: none;
  padding: 0;
  margin: 0 6px 0 2px;
  align-self: center;
  width: 18px;
  height: 18px;
  color: var(--deloitte-green);
  &:hover {
    cursor: pointer;
  }
}

.teams_list_item:hover .edit_icon {
  display: block;
}

.admin_icon {
  padding: 0;
  margin: 0;
  align-self: center;
  width: 16px;
  height: 16px;
  color: var(--deloitte-green);
}

.body {
  flex: 1;
  display: flex;
  overflow-y: auto;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.menu_content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_icon {
  font-size: 20;
  color: var(--deloitte-green);
}
