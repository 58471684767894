.content {
  margin-top: 30px;
}

.icon {
  width: 30px;
  height: 30px;
  color: var(--deloitte-green);
  margin-bottom: 15px;
}

.footer {
  text-align: center;
  margin-top: 40px;
}

.confirm_button {
  width: 100%;
}
