.selected_files_table_container {
  width: 100%;
  margin-left: 50px;
  background-color: #262730;
  padding: 16px;
  border-radius: 8px;
}

.title {
  font-size: 20px;
}

.sub_title {
  font-size: 16px;
}

.file_name {
  width: 250px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
