.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: unset;
  font-size: unset;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: unset;
}

.ant-carousel .slick-prev::before,
.ant-carousel .slick-next::before {
  content: "";
}

.ant-carousel .slick-prev {
  inset-inline-start: 0px;
  z-index: 1;
  margin-left: -20px;
}

.ant-carousel .slick-next {
  inset-inline-end: 0px;
  margin-right: -20px;
}

.carousel-arrow-icon:hover {
  color: var(--deloitte-green);
}
