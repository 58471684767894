

.flex_box_text_card{
    position: fixed;
    z-index: 9999;
    right: 3vh;
    height: 82vh;
    top: 12vh;
    bottom: 3vh;
    width: 50%;
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(27, 27, 27, 0.8);

  


}
.flex_box_text_inner{
    overflow-y: auto;
    height: calc(80vh - 70px );
    /* padding: 20px; */
    overflow-y: auto;
    overflow-x: hidden;
 
    border-radius: 8px;
 

}



.close_button {
    position: absolute;
    top: 5px;
    right: 5px;
 
}

.text_title {
    margin-top: 10px;
    margin-bottom: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.text_title > h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 16px;
    font-weight: 800;
    line-height: 1.5;
}

.highlight-text {
    background-color:rgb(117, 163, 33, 0.5);
    font-weight: 600;
     
    padding-right: 4px;
}

.text_content {
    white-space: pre-wrap;
    word-wrap: break-word;
    color: #fff;
    font-size: 14px;

}