.container {
  border: 1px solid #525860;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}

.icon {
  font-size: 32px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0;
}
