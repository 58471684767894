.content {
  margin-top: 30px;
  text-align: center;
}

.icon {
  width: 30px;
  height: 30px;
  color: var(--deloitte-green);
  margin-bottom: 15px;
}

.modal_title {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 300;
}

.footer {
  text-align: center;
  margin-top: 40px;
}

.confirm_button {
  width: 100%;
}
