.form {
  padding: 10px 2px;
  display: flex;
  flex-direction: column;
}

.language_input {
  margin-bottom: 40px;
}

.button_icon {
  width: 20px;
  height: 20px;
}

.modal_icon {
  width: 30px;
  height: 30px;
}

.tool_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.description_tooltip {
  max-width: 600px;
  white-space: normal;
  word-wrap: break-word;
}

.description_content {
  margin-bottom: 10px;
}

.italic_text {
  font-style: italic;
  font-weight: 700;
}

.disclamer_button {
  margin-left: 40px;
}

.disclamer_icon {
  animation-duration: 0.7s;
  animation-name: iconAppearance;
}

.description.hidden {
  max-height: 0;
}

.description {
  padding: 0 5px;
  max-height: 300px;
  overflow: hidden;
  display: flex;
  gap: 15px;
  font-style: italic;
  line-height: 1.8;
  text-align: justify;
}

.description::before {
  content: "";
  border-left: 1px solid var(--deloitte-green);
  border-right: 1px solid var(--deloitte-green);
  padding: 2px;
}

.divider {
  background-color: #151515;
  height: 1px;
  border-radius: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.result_name_field {
  margin-top: 20px;
}

.bold_italic_text {
  font-style: italic;
  font-weight: 700;
}

.groupped_items {
  border: 1px solid #424242;
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 20px;
}

.custom_metadata_entry_title {
  text-align: center;
  margin: 5px 0 10px;
  font-size: 16px;
  font-weight: 700;
}

.custom_metadata_entry {
  display: flex;
  gap: 10px;
}

.editing_buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}
