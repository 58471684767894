.empty_state_uploaders_container {
  display: flex;
  gap: 20px;
}

.file_upload_dragger_icon {
  font-size: 36px;
  transition: 0.3s;
}

.file_upload_dragger:hover .file_upload_dragger_icon {
  color: var(--deloitte-green);
}
