p {
  margin: 0;
}

.list_item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.topbar {
  box-sizing: border-box;
  height: 60px;
  padding: 16px;
  background-color: #262730;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 16px;
}

.navigation_item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navigation_item_selected {
  color: var(--deloitte-green) !important;
}

.navigation_item_text {
  border: none;
  background: none;
  color: #fff;
  font-weight: 700;
  transition: 0.3s;
  &:hover {
    color: #b7b7b7;
  }
}

.navigation_project_name {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  font-style: italic;
  width: 400px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
