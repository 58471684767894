.empty_state_container {
  width: 100%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 140px;
}

.text {
  font-size: 16px;
  text-align: center;
  width: 350px;
}

.arrow_picture {
  transform: translateX(-140px);
}
