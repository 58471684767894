@keyframes slideIn {
  from {top: 25%; opacity: 0;}
  to {top: 20%; opacity: 1;}
}

.invite_member_modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .2);
  z-index: 1000;
}

.invite_member_modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background-color: #262730;
  padding: 26px 36px 36px 36px;
  z-index: 1000;
  border-radius: 15px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.8);
  animation: slideIn .1s;
  min-width: 500px;
}

.invite_member_modal_submit_button {
  background-color: var(--deloitte-green);
  border-color: #777;
}

.invite_member_modal_close_button {
  position: absolute;
  right: 30px;
  top: 20px;
  color: #9ea6a2;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #525860;
    cursor: pointer;
    border-radius: 15%;
  }
}

.invite_member_user_tag {
  display: flex;
  overflow-x: hidden;
}