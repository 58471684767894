.connected_tab_container {
  margin: 0 20px 20px;
  height: calc(100vh - 309px);
  overflow: auto;
}

.connected_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}

.list_container {
  width: 50%;
}

.list {
  height: calc(100vh - 397px);
  overflow: hidden auto;
}

.list_item {
  padding-left: 0px !important;
  justify-content: flex-start !important;
}

.nested_files {
  margin-left: 30px;
}

.checkbox_container {
  width: 100%;
  display: flex;
  align-items: center;
}

.checkbox_content {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
}

.file_name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;
}

@media (max-width: 1300px) {
  .file_name {
    max-width: 350px;
  }
}

@media (max-width: 1200px) {
  .file_name {
    max-width: 300px;
  }
}

@media (max-width: 1100px) {
  .file_name {
    max-width: 250px;
  }
}

@media (max-width: 1000px) {
  .file_name {
    max-width: 230px;
  }
}

@media (max-width: 950px) {
  .file_name {
    max-width: 220px;
  }
}
