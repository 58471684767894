.uploaders_container {
  display: flex;
  gap: 20px;
}

.uploader_tooltip {
  max-width: 400px;
}

.title_container {
  text-align: center;
}

.hint {
  margin-bottom: 10px;
  font-weight: 700;
}

.accepted_types {
  margin-top: 5px;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid var(--deloitte-green);
}

.file_upload_dragger {
  height: 42px;
  width: 130px;
  margin: 10px 10px 14px;
}
.file_upload_dragger > div {
  background-color: #23232a !important;
}

.file_upload_dragger > div > span {
  padding: 0 !important;
}

.file_upload_dragger_text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.file_upload_dragger_icon {
  font-size: 20px;
  transition: 0.3s;
}

.file_upload_dragger:hover .file_upload_dragger_icon {
  color: var(--deloitte-green);
}
