.files_bar {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  max-width: 350px;
  background-color: #23232a;
  transition: 0.3s;
}

.files_bar_header {
  margin: 10px 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.files_bar_tabs {
  height: 100%;
  flex-grow: 1;
}

.files_bar.closed {
  min-width: 60px;
  max-width: 60px;
}

.tab_label {
  padding: 0 30px;
}

.toggle_button {
  display: flex;
  margin-right: 10px;
  padding: 4px 6px 6px 4px;
  color: #9ea6a2;
  font-size: 20px;
  cursor: pointer;
  border-radius: 15%;
  transition: 0.3s;
  z-index: 100;
  &:hover {
    background-color: #525860;
  }
}

.toggle_button.closed {
  transform: scaleX(-1);
}

@media (max-width: 1150px) {
  .files_bar {
    display: none;
  }
}

.files_list_title_text {
  font-size: 16px;
  font-weight: 700;
}

.files_delete_button {
  color: var(--beautiful-red);
  line-height: 1;
  padding: 2px 5px !important;
  &:hover {
    background-color: var(--beautiful-red) !important;
  }
  &:disabled {
    background: none !important;
  }
}

.files_delete_icon {
  width: 20px;
  height: 20px;
}

.file_delete_wrapper {
  opacity: 0;
  line-height: 1;
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 8px;
  margin-top: 2px;
}

.files_list_item:hover .file_delete_wrapper {
  opacity: 1;
}

.file_delete_icon {
  width: 20px;
  height: 20px;
  color: #fff;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: #ffffff98;
  }
}

.clear_file_process {
  display: none;
}

.file_process:hover .file_process_spinner {
  display: none;
}

.file_process:hover .clear_file_process {
  display: block;
}

.folder_icon,
.file_icon {
  opacity: 0.7;
  margin-right: 4px;
}
