@keyframes opacityAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.growEnter {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.growEnterActive {
  opacity: 1;
  max-height: 500px;
  transition: opacity 300ms, max-height 300ms ease-in;
}

.shrinkExit {
  opacity: 1;
  max-height: 500px;
  overflow: auto;
}

.shrinkExitActive {
  opacity: 0;
  max-height: 0;
  transition: opacity 300ms, max-height 300ms ease-out;
}

.tool {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #23232a;
  margin: 10px 0;
  animation: opacityAnimation 0.3s ease-in-out;
}

.tool_title {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.ribbon {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  --r: 0.4em;
  border-inline: 0.5em solid #0000;
  padding: 0.2em 0.2em calc(var(--r) + 0.1em);
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    calc(100% - 0.5em) 100%,
    50% calc(100% - var(--r)),
    0.5em 100%,
    0 100%
  );
  background: radial-gradient(50% 0.1em at top, #000a, #0000) border-box,
    var(--deloitte-green) padding-box;
}

.tool_construction {
  font-size: 14px;
  margin-left: 10px;
}

.tool_content {
  text-align: center;
  font-size: 14px;
  margin: 20px;
  padding: 10px;
  border: 1px solid var(--deloitte-green);
  border-radius: 10px;
}
