.approval_container {
  margin: 0 20px 20px;
  height: calc(100vh - 240px);
  overflow: auto;
}

.for_approve_connections_container {
  margin-top: 10px;
  height: calc(100vh - 342px);
  overflow: auto;
}

.group_name {
  display: flex;
  gap: 20px;
  font-weight: 600;
}

.decision_buttons_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.reject_button {
  background-color: var(--beautiful-red);
}

.parent_file {
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.child_file {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.parent_icon {
  color: var(--deloitte-green);
  font-size: 24px;
}

.child_icon {
  color: var(--deloitte-green);
  font-size: 20px;
}

.list_item {
  justify-content: flex-start !important;
  gap: 10px;
}

.child_file_name {
  cursor: pointer;
  width: 90%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file_name {
  cursor: pointer;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
