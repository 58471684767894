.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  color: #fff;
}

.list_item {
  display: flex;
  align-items: center;
  gap: 10px;
}
