.notification_bell_container {
  position: relative;
}

.notifications_counter {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 45%;
  left: 40%;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--deloitte-green);
  border: 2px solid #262730;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #000;
}

.title_notifications_counter {
  line-height: 1.8;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--deloitte-green);
  font-size: 12px;
  text-align: center;
  color: #000;
}

.header {
  width: 600px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 20px;
  padding: 10px 0;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.85);
}

.read_all_notifications_icon {
  width: 24px;
  height: 24px;
  transition: color 0.1s ease-in-out;
  color: rgba(255, 255, 255, 0.85);
  &:hover {
    cursor: pointer;
    color: #ffffff87;
  }
}

.clear_notifications_icon {
  width: 22px;
  height: 22px;
  margin-left: 8px;
  transition: color 0.1s ease-in-out;
  color: rgba(255, 255, 255, 0.85);
  &:hover {
    cursor: pointer;
    color: #ffffff87;
  }
}

.overlay {
  max-height: 600px;
  width: 650px;
  overflow: hidden auto;
  background-color: #1f1f1f;
  border-radius: 8px;
}

.empty_state {
  align-self: center;
  justify-self: center;
  padding: 20px 0;
  color: rgba(255, 255, 255, 0.85);
}
