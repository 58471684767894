.tool_button {
  font-size: 12px;
  width: auto;
  padding: 0 6px 3px;
  min-width: max-content;
  text-align: center;
  user-select: none;
  border-radius: 10px;
  &:hover {
    background-color: #434348;
    cursor: pointer;
  }
}

.tool_button_selected {
  background-color: #525860 !important;
  box-shadow: inset 0 3px 30px rgba(0, 0, 0, 0.163) !important;
}

.tool_button_in_dev {
  color: grey;
}
