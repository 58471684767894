.bold_italic_text {
  font-style: italic;
  font-weight: 700;
}

.title {
  font-weight: 700;
  margin-bottom: 5px;
}

.info {
  margin-bottom: 15px;
}

.info_icon {
  margin-left: 10px;
}
