.content {
  display: flex;
  align-items: center;
}

.files_retention_switch {
  margin-left: 16px;
}

.change_retetion_period_button {
  margin-left: 16px;
  height: 22px;
  border: 0;
  padding: 0;
}

.info_circle {
  margin-left: 16px;
  cursor: pointer;
}