.expanded_result_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  height: 32px;
}

.expanded_result_title {
  font-weight: 700;
}

.result_buttons {
  display: flex;
  gap: 10px;
}

.changings_buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.editing_wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.is_edited_info {
  font-style: italic;
  color: #838383;
}

.editable_content {
  color: #fff;
  max-height: 50vh;
  overflow: auto;
}

.expanded_result_content {
  padding: 10px;
  max-height: 50vh;
  overflow: auto;
}
