@font-face {
  font-family: 'openSans-light';
  src: local('openSans-light'), url(./fonts/OpenSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'openSans-extra-bold';
  src: local('openSans-extra-bold'), url(./fonts/OpenSans-ExtraBold.ttf) format('truetype');
}

body {
  font-family: 'openSans-light', sans-serif;
  background-color: rgba(14, 17, 23, 1);
  color: white;
  margin: 0;
}

pre {
  white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
  white-space: -pre-wrap; /* Opera */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
  word-wrap: break-word; /* IE 5.5+ */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
}
#listboxContainer > div > div:nth-child(2) {
  z-index: 1000!important;
}  