.action_buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

tr:has(.empty_state) {
  z-index: 0 !important;
}
.table_actions {
  display: flex;
  gap: 8px;
  margin-bottom: 12px; /* reduced from 16px */
}

/* Add these new styles */
.table_actions :global(.ant-btn) {
  padding: 4px 12px;  /* reduced padding */
  height: 32px;       /* reduced height from default 40px */
  font-size: 10px;    /* slightly smaller font */
}

.table_actions :global(.ant-upload) {
  display: block;  /* ensures upload button follows same sizing */
}