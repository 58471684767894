.content {
  margin: 15px 0 25px;
  text-align: center;
  font-size: 16px;
}

.icon {
  font-size: 26px;
  padding: 11px;
  background-color: #3d3f4a;
  border-radius: 50%;
  margin-bottom: 15px;
}

.bottom_gap {
  margin-bottom: 15px;
}

.footer {
  text-align: center;
  margin-top: 40px;
}

.confirm_button {
  width: 100%;
}
