.tools_page {
  display: flex;
  overflow: hidden;
  height: calc(100% - 60px);
}

@media (max-width: 1150px) {
  .tools_spot {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }
}

.tools_spot {
  width: 73%;
  display: flex;
  flex-direction: column;
}

.selected_tools {
  padding: 0 10px;
  flex-grow: 1;
  overflow-y: auto;
}
