@keyframes opacityAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.page {
  display: flex;
  height: 100vh;
  margin: 0;
}

.side_menu {
  display: flex;
  flex-direction: column;
  width: 258px;
  max-width: 258px;
  background-color: #262730;
  transition: 0.2s;
  position: relative;
}

@media (max-width: 900px) {
  .side_menu {
    display: none;
  }
}

.side_menu.closed {
  width: 50px;
}

.menu_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 0;
  overflow: auto;
  animation: opacityAnimation 0.3s ease-in-out;
}

.loading_container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader_icon {
  font-size: 20px;
  color: var(--deloitte-green);
}

.teams_empty {
  font-size: 12px;
  flex: 1;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.teams_empty_text {
  margin-bottom: 10px;
}

/* Scrollbar style -- START */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  position: absolute;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 20px;
  right: -10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #444;
}

/* Scrollbar style -- END */

.menu_list {
  flex: 1;

  /* max-height: 675px; */
  /* height: 100vh; */
  margin-bottom: 15px;
  /* padding-right: 4px; */
  /* overflow: auto; */
  overflow-y: scroll;
}

.user_section {
  display: flex;
}

.manage_my_teams_anchor {
  color: var(--deloitte-green);
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 2px 0;
}

.manage_my_teams_anchor:hover {
  cursor: pointer;
  text-decoration: underline;
}

.new_project_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 0 10px 14px 10px;
  font-size: 14px;
  border: 1px solid #525860;
  border-radius: 8px;
  user-select: none;
  animation: opacityAnimation 0.3s ease-in-out;
  font-weight: 700;
  z-index: 1;
}

.new_project_button:hover {
  cursor: pointer;
  background-color: #525860;
}

.new_project_button_icon {
  color: #525860;
}
