.description {
  padding: 0 5px;
  display: flex;
  gap: 15px;
  font-style: italic;
  line-height: 1.8;
  text-align: justify;
}

.description::before {
  content: "";
  border-left: 1px solid var(--deloitte-green);
  border-right: 1px solid var(--deloitte-green);
  padding: 2px;
}
