.growEnter {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.growEnterActive {
  opacity: 1;
  max-height: 500px;
  transition: opacity 300ms, max-height 300ms ease-in;
}

.shrinkExit {
  opacity: 1;
  max-height: 500px;
  overflow: auto;
}

.shrinkExitActive {
  opacity: 0;
  max-height: 0;
  transition: opacity 300ms, max-height 300ms ease-out;
}