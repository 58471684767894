@keyframes iconAppearance {
  from {
    opacity: 0;
  }

  to {
    opacity: 100%;
  }
}

.disclamer_container.hidden {
  height: 0;
  max-height: 0;
}

.disclamer_container.hidden::before {
  opacity: 0;
}

.disclamer_container {
  padding: 0 5px;
  height: 250px;
  max-height: 250px;
  display: flex;
  align-items: stretch;
  gap: 15px;
  line-height: 1.8;
  text-align: justify;
  transition: 0.5s;
}

.disclamer_container::before {
  content: "";
  border-left: 1px solid var(--deloitte-green);
  border-right: 1px solid var(--deloitte-green);
  padding: 2px;
  transition: 0.5s;
}

.disclamer {
  overflow: auto;
}

.disclamer_button {
  margin-left: 40px;
  background-color: #87ceeb !important;
  color: #000 !important;
}

.disclamer_icon {
  animation-duration: 0.7s;
  animation-name: iconAppearance;
}

.disclamer_title {
  font-weight: 700;
  margin-bottom: 5px;
}

.disclamer_info {
  font-style: italic;
  margin-bottom: 15px;
}

.divider {
  background-color: #151515;
  height: 1px;
  border-radius: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.language_input {
  margin-bottom: 20px;
}

.bilingual_checkbox {
  margin-bottom: 20px;
}

.button_icon {
  width: 20px;
  height: 20px;
}

.modal_icon {
  width: 30px;
  height: 30px;
}

.tool_footer {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result_name_field {
  margin-top: 20px;
}

.bold_italic_text {
  font-style: italic;
  font-weight: 700;
}
