.layout {
  display: flex;
  flex-direction: column;
  background-color: #141414;
  margin: 0 20px 20px 40px;
}

.title {
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 24px;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #141414;
  font-weight: bold;
  border-bottom: solid 1px #303030;
  margin-bottom: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;
}
