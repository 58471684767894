@keyframes slideIn {
  from {top: 55%; opacity: 0;}
  to {top: 50%; opacity: 1;}
}

.remove_member_modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .2);
  z-index: 1000;
}

.remove_member_modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background-color: #262730;
  padding: 26px 36px 36px 36px;
  z-index: 1000;
  border-radius: 15px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.8);
  animation: slideIn .1s;
  min-width: 500px;
}

.remove_member_modal_close_button {
  width: 64px;
  height: 40px;
  background-color: #212529;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #9c9c9c;
}

.remove_member_modal_close_button:hover {
  background-color: #0e11174c;
}

.remove_member_modal_submit_button {
  width: 64px;
  height: 40px;
  background-color: var(--beautiful-red);
  border: 1px solid #777;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
