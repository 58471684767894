.layout {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.content {
  display: flex;
  align-items: center;
  background-color: rgba(14, 17, 23, 1);
  box-shadow: 0 8px 6px 2px rgba(14, 17, 23, 1);
  z-index: 1000;
}

.text {
  font-size: 24px;
  font-weight: bold;
  padding: 20px;
  padding-left: 40px;
}

.disclaimer {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding-right: 22px;
  font-size: 12px;
  padding-top: 2px;
}
