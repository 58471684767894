.bottom_gap {
  margin-bottom: 15px;
}

.file_list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 40vh;
  overflow-y: auto;
  margin-bottom: 20px;
}

.file_name {
  width: 280px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.footer {
  display: flex;
  align-items: center;
  gap: 14px;
}

.button {
  width: 100%;
}
