.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.empty_state {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.menu {
  height: 69px;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #262730;
}

.navigation {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  gap: 16px;
}

.navigation_item {
  cursor: pointer;
}

.navigation_item_selected {
  color: var(--deloitte-green) !important;
}

.navigation_item_text {
  border: none;
  background: none;
  color: #fff;
  font-weight: 700;
  transition: 0.3s;
  &:hover {
    color: #b7b7b7;
  }
}

.body {
  flex: 1;
  display: flex;
  overflow-y: auto;
}

.members_tab_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.members_tab_content_title {
  display: flex;
  align-items: center;
  background-color: rgba(14, 17, 23, 1);
  box-shadow: 0 8px 6px 2px rgba(14, 17, 23, 1);
  z-index: 1000;
}

.members_list { 
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
}


.admin_icon {
  padding: 0;
  margin: 0;
  width: 16px;
  height: 16px;
  color: var(--deloitte-green);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.tools_content {
  display: flex;
  align-items: center;
  /* box-shadow: 0 8px 6px 2px rgba(14, 17, 23, 1); */
  /* z-index: 1000; */
}

.tools_title {
  padding: 20px 20px 20px 40px;
  font-size: 24px;
  font-weight: bold;
}

.tools_select {
  padding: 20px 0;
}

.inputs_content {
  flex: 1;
  display: flex;
  overflow-y: auto;
}
