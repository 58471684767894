.file_actions_bar {
  padding: 6px 12px;
  background-color: #23232a;
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 22px;
  border: 1px solid #9c9c9c;
  border-radius: 10px;
}

.file_action_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;
  font-size: 12px;
  padding: 5px 8px;
  min-width: max-content;
  user-select: none;
  border-radius: 10px;
  transition: 0.3s;
  &:hover {
    background-color: #434348;
    cursor: pointer;
  }
}

.action_disabled {
  pointer-events: none;
  opacity: 0.6;
}

.action_inactive {
  pointer-events: none;
  opacity: 0.2;
}

.file_action_icon {
  font-size: 26px;
}
