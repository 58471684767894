.footer {
  display: flex;
  align-items: center;
  gap: 14px;
}

.close_button {
  width: 100%;
  height: 40px;
  background-color: #212529;
  border: 1px solid #9c9c9c;
  &:hover {
    background-color: #0e11174c;
  }
}

.submit_button {
  width: 100%;
  height: 40px;
  border: 1px solid #777;
  background-color: #c03029;
}

.content {
  margin: 15px 0 25px;
  text-align: center;
  font-size: 16px;
}

.icon {
  width: 48px;
  height: 48px;
  padding: 11px;
  color: #c03029;
  background-color: rgba(192, 49, 41, 0.2);
  border-radius: 50%;
  margin-bottom: 15px;
  transform: rotate(180deg);
}

.user_fullname {
  font-weight: 600;
}

.user_email {
  font-style: italic;
}

.bottom_gap {
  margin-bottom: 15px;
}
