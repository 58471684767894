.tools_bar_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tools_bar {
  margin: 10px 10%;
  padding: 6px;
  background-color: #23232a;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 8px;
  border: 1px solid #9c9c9c;
  border-radius: 10px;

  position: relative;
}

.tool_icon {
  width: 28px;
  height: 38px;
}

.dropdown_gap {
  margin-bottom: 16px;
}

.additional_tools {
  z-index: 1;
  box-sizing: border-box;
  padding: 2px 0 10px;
  background-color: #23232a;

  border: 1px solid #9c9c9c;
  border-radius: 8px;
  position: absolute;
  bottom: 75px;
  right: 0;
  transition: 0.5s;
}

.closed {
  max-height: 0;
  border: none;
  padding: 0;
  overflow: hidden;
}

.tool_button_container {
  margin: 6px;
}
