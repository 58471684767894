@keyframes opacityAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.disabled_icon {
  display: none;
  opacity: 0;
  pointer-events: none;
  cursor: default;
  
}
.projects_empty {
  font-size: 12px;
  text-align: center;
  margin: 15px;
}

.period {
  padding: 8px 0 2px 8px;
  margin: 0 4px 0 10px;
  font-size: 12px;
  font-weight: 700;
  color: #666;
  animation: opacityAnimation 0.3s ease-in-out;
}

.menu_item {
  padding: 8px;
  margin: 0 4px 0 10px;
  font-size: 14px;
  animation: opacityAnimation 0.3s ease-in-out;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: #5258607c;
  }
}

.menu_item_selected {
  background-color: #525860;
  border-radius: 8px;
  &:hover {
    background-color: #525860;
  }
}

.menu_item:hover .menu_item_icon_wrapper {
  display: inline;
}

.menu_item_content {
  width: 80%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.menu_item_selected .menu_item_icon_wrapper {
  display: inline;
}

.menu_item_icon_wrapper {
  display: none;
  height: 20px;
  line-height: 1;
}

.menu_item_icon {
  width: 20px;
  height: 20px;
  color: #fff;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: #ffffff98;
  }
}

.search_input {
  width: 95%;
  margin-left: 4%;
}

.bold_italic_text {
  font-style: italic;
  font-weight: 700;
}
