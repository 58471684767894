.sort_icon {
  width: 20px;
  height: 20px;
}

.file_extension {
  color: #6b8eae;
}

.search_input {
  width: calc(100% - 20px);
  margin: 0 10px 10px;
}

.files_list_title {
  width: 100%;
  padding: 6px 6px 6px 5px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.files_sort_button {
  color: #fff;
  line-height: 1;
}

.items_container {
  height: calc(100vh - 60px - 40px - 62px - 42px - 44px);
  overflow-y: auto;
}

.files_list_item {
  padding: 0px 5px !important;
  min-height: 40px;
  height: auto;
  align-items: flex-start;
  &:hover {
    background-color: #434348;
  }
}

.files_list_item_selected {
  background-color: #525860;
}

.files_list_item:hover .file_checkbox {
  opacity: 1;
}

.files_list_item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.02);
}

.files_list_item:hover .file_name {
  color: #ffffff;
}

.files_list_item:hover .folder_path {
  color: #a1a1a1;
}

.processing_file {
  opacity: 0.5;
}

.file_process_spinner {
  display: block;
}

.file_checkbox {
  opacity: 0;
  flex-shrink: 0;
  margin-top: 2px;
}

.file_checkbox_checked {
  opacity: 1;
}

.files_list_item_name {
  width: 100%;
  margin: 0 10px;
  font-size: 10px;
  overflow-x: hidden;
  white-space: normal;
  word-break: break-word;
  line-height: 1.4;
  padding: 2px 0;
}

.preview_button {
  cursor: pointer;
}

.folder_path {
  font-size: 0.9em;
  color: #8b8b8b;
}

.file_name {
  color: #e1e1e1;
}

.empty_state {
  font-size: 14px;
  text-align: center;
  margin: 20px;
  border: 1px solid #525252;
  border-radius: 5px;
  padding: 20px;
}

.empty_state_title {
  margin: 10px 0 5px;
  font-size: 16px;
  font-weight: 700;
}

.files_retention_period {
  color: yellow;
  font-size: 10px;
  cursor: pointer;
  margin-right: 10px;
}