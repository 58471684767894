.content {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 60px);
}

.actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 22px 20px;
}
