.text_layout {
  display: flex;
  margin: 4px 12px 0 12px;
}

.text {
  font-size: 12px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}