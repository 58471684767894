.loading_dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 6px;
  height: 6px;
  margin: 0 0.8px;
  background-color: white;
  border-radius: 50%;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-flashing {
  0% {
    opacity: 0;
  }
  50%,
  100% {
    opacity: 1;
  }
}