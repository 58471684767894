.tools_spot_empty_container {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.tools_spot_empty {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: #666;
}

.green_word {
  color: var(--deloitte-green);
}

.arrow_down {
  width: 110px;
  position: relative;
  left: -20px;
}
